@import 'app-theme';
@import 'utils';
@tailwind base;
@tailwind components;
@tailwind utilities;
.anchor {
  @apply text-blue-700 underline hover:cursor-pointer;
}
h1 {
    @apply text-2xl font-semibold leading-8 mb-8;
  }

  h2 {
    @apply text-xl font-semibold leading-7 mb-6;
  }

  h3 {
    @apply text-lg font-medium leading-6 mb-4;
  }
  h4 {
    @apply text-base font-medium leading-5 mb-2;
  }
  h5 {
    @apply text-sm font-medium leading-4;
  }
  h6 {
    @apply text-xs leading-3;
  }

  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-right-style: hidden;
  }
  @layer utilities {
    button {
      span {
        @apply capitalize;
      }
    }
    .image--pulsing {
      animation: pulse 1s infinite ease-in-out;
      min-height: 250px;
    }
    .pragraph--pulsing {
      animation: pulse 1s infinite ease-in-out;
      height: 1rem;
      margin: 8px;
      @apply text-transparent;
    }
    .button--pulsing {
      span,mat-icon {
        @apply text-transparent;
      }
    }
    
    .button--pulsing.mat-flat-button.mat-primary {
      animation: pulse 1s infinite ease-in-out;
    }
    
    .button--pulsing.mat-icon-button {
      animation: pulse 1s infinite ease-in-out;
    }
    @keyframes pulse {
      0% {
        background-color: rgba(165, 165, 165, 0.1);
      }
      50% {
        background-color: rgba(165, 165, 165, 0.3);
      }
      100% {
        background-color: rgba(165, 165, 165, 0.1);
  
      }
    }
  }